import React, { useEffect, useState } from 'react'
import { css } from '../../../styled-system/css'
import { GalleryPageData } from '../../types/gallery'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'


export const ThemeChoice: React.FC = ({}) => {
  const location = useLocation()
  const breakpoints = useBreakpoint()
  const [isTop, setIsTop] = useState(true)
  const parts = location.pathname.split('/photographie/')
  const afterPhotographie = parts.length > 1 ? parts[1].replace(/\/$/, '') : ''
  const beforeDash = afterPhotographie.split('-')[0].trim()

  let topCoordinateMenu = '70px';

  if (!breakpoints.xs && !breakpoints.sm) {
    topCoordinateMenu = isTop ? '154px' : '84px'
  }
  const galerieMenuStyle = css({
    position: 'fixed',
    padding: '10px',
    transition: 'all 0.3s ease-in-out',
    zIndex: '99999',
    height: '50px',
    width: '100%',
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #d9c58b'
  });

  useEffect(() => {
    const handleScroll = () => {
      if (breakpoints.md) {
        return
      }

      const atTop = window.scrollY === 0
      setIsTop(atTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [breakpoints])

  useEffect(() => {
    const handleScroll = () => {
      if (breakpoints.md) {
        return
      }

      const atTop = window.scrollY === 0
      setIsTop(atTop)
    }
  });

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        flexDir: 'column',
        p: '20px 0',
        gap: '20px',
        // maxW: '1700px',
        margin: 'auto',
        overflow: 'hidden'
      })}
    >
      <nav
      style={{top: topCoordinateMenu}}
        className={`${css({
          overflowX: 'auto',
          overflowY: 'hidden'
        })} ${galerieMenuStyle} galerie-menu` }
      > 
        <ul
          className={css({
            display: 'flex',
            gap: '5px',
            textTransform: 'uppercase',
            fontFamily: 'Exo 2',
            fontWeight: 'bold',
            fontSize: '18px',
            flexWrap: 'no-wrap',
            flexDirection: 'row',
            p: '5px',
            justifyContent: 'center',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none', // Pour Chrome, Safari et Opera
            },
          })}
        >
          {GalleryPageData.filter((c) => !c.hidden).map((category, index) => {
            
            const isActive = beforeDash === `${category.id}`
            const isFirst = index === 0;
            return (
              <li
                key={category.id}
                className={css({
                  padding: '10px 10px 10px 40px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                  userSelect: 'none',
                  color: isActive ? '#fff' : '#ccc',
                  opacity: isActive ? '1' : '.5',
                  transition: '.3s',
                  pos: 'relative',
                  
                  _before: {
                    content: !isFirst ? '""': '',
                    pos: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '6px', 
                    height: '6px', 
                    backgroundColor: '#d9c58b',
                    borderRadius: '50%'
                  }
                })}
              >
                <Link to={`/photographie/${category.id}`} className={css({
                  borderBottom: isActive
                    ? '3px solid black'
                    : '3px solid transparent'})}>
                  {category.category === 'Baptème' ? 'Baptême' : category.category}
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}
