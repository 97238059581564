import React from 'react';
import { css } from '../../../styled-system/css';
import ResponsiveImage from './ResponsiveImage';

interface ImageGalleryProps {
  images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  return (
    <div className={css({ display: 'flex', flexDirection: 'row', gap: '2rem' })}>
      {images.map((image, index) => (
        <div key={index} className={css({ display: 'flex', justifyContent: 'center' })}>
          <ResponsiveImage
            className={css({
              width: '100%',
              height: '20%',
              objectFit: 'cover',
            })}
            imageName={image}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
