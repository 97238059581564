export type SEO = {
  title: string
  description: string
  robotsContent: string
}

export type Gallery = {
  id: string
  category: string
  hidden?: boolean
  titre: string
  titres?: string[]
  DispImage?: string[]
  images?: string[]
  cover?: string
  HtmlText?: string[]
  SEO: SEO
}

export const GalleryPageData: Gallery[] = [
  {
    id: 'naissance-lille',
    category: 'Naissance',
    hidden: true,
    titre: '',
    titres: [
      'Votre shooting photo naissance à Lille',
      'Pourquoi choisir une séance photo naissance à Lille',
      'Mettez en valeur chaque instant avec une séance photo naissance à Lille',
      'Explorez votre galerie privée après chaque shooting naissance',
    ],
    cover: 'photographe-naissance-lille@digituniverse_0001',
    HtmlText: [
      'gal_naissance_lille_pres.mdx',
      'gal_naissance_lille_text_1.mdx',
      'gal_naissance_lille_text_2.mdx',
      'gal_naissance_lille_text_3.mdx',
      'gal_naissance_lille_text_4.mdx',
    ],
    DispImage: [
      'photographe-naissance-lille@digituniverse_0002',
      'photographe-naissance-lille@digituniverse_0003',
      'photographe-naissance-lille@digituniverse_0004',
      'photographe-naissance-lille@digituniverse_0005',
    ],
    SEO: {
      title: 'Photographe pour Naissance à Lille - Shooting Photo Professionnel',
      description:
        'Photographe de Naissance à Lille. Séances photo professionnelles pour capturer les moments précieux.',
      robotsContent: 'index, follow',
    },
  },
  {
    id: 'naissance',
    category: 'Naissance',
    titre: 'Galerie photo naissance - premiers instants de votre bébé',
    titres: [
      'Découvrez la beauté des séances photo naissance',
      'Capturer les moindres détails',
      'Une séance photo naissance à votre image',
      'Accédez à votre galerie privée',
    ],
    cover: 'photographe-naissance-lille@digituniverse_0001',
    HtmlText: [
      'gal_naissance_pres.mdx',
      'gal_naissance_text_1.mdx',
      'gal_naissance_text_2.mdx',
      'gal_naissance_text_3.mdx',
      'gal_naissance_text_4.mdx',
    ],
    DispImage: [
      'photographe-naissance-lille@digituniverse_0002',
      'photographe-naissance-lille@digituniverse_0003',
      'photographe-naissance-lille@digituniverse_0004',
      'photographe-naissance-lille@digituniverse_0001',
    ],
    SEO: {
      title: 'Photographe pour Naissance - Shooting Photo Professionnel',
      description:
        'Séances photo professionnelles de Naissance. Capturez vos moments précieux avec un photographe spécialisé.',
      robotsContent: 'index, follow',
    },
  },
  // {
  //   id: 'bapteme-lille',
  //   category: 'Baptème',
  //   hidden: true,
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   images: ['photographe-grossesse-lille@digituniverse_0007', 'photographe-grossesse-lille@digituniverse_0007'],
  //   SEO: {
  //     title: 'Photographe pour Baptême à Lille - Shooting Photo Professionnel',
  //     description:
  //       'Photographe de Baptême à Lille. Séances photo professionnelles pour capturer les moments précieux.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
    {
      id: 'bapteme',
      category: 'Baptème',
      titre: 'Galerie photo baptême - Capturez votre cérémonie',
      titres: [
        'Photographe baptême à Lille : capturez votre cérémonie',
        'Pourquoi choisir un photographe professionnel pour votre baptême ?',
        'Mon approche pour vos photos de baptême à Lille',
        'Capturer la beauté de chaque instant : un reportage photo baptême personnalisé',
        'Offrez-vous une expérience unique avec une séance photo de baptême',
        'Réservez votre reportage photo de baptême à Lille',
      ],
      cover: 'photographe-bapteme-lille@digituniverse_0001',
      HtmlText: [
        'gal_bapteme_pres.mdx',
        'gal_bapteme_text_1.mdx',
        'gal_bapteme_text_2.mdx',
        'gal_bapteme_text_3.mdx',
        'gal_bapteme_text_4.mdx',
        'gal_bapteme_text_5.mdx',
        'gal_bapteme_text_6.mdx'
      ],
      DispImage: [
        'photographe-baptème-lille@digituniverse_0002.jpg',
        'photographe-baptème-lille@digituniverse_0028.jpg',
        'photographe-baptème-lille@digituniverse_0007.jpg',
        'photographe-baptème-lille@digituniverse_0008.jpg',
      ],
      SEO: {
        title: 'Photographe pour baptême à Lille - Shooting Photo Professionnel',
        description:
          'Photographe de baptême à Lille. Séances photo professionnelles pour capturer chaque moment précieux de votre cérémonie.',
        robotsContent: 'index, follow',
      },
    },
  // {
  //   id: 'mariage-lille',
  //   category: 'Mariage',
  //   hidden: true,
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Mariage à Lille - Shooting Photo Professionnel',
  //     description:
  //       'Photographe de Mariage à Lille. Séances photo professionnelles pour capturer les moments précieux.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'mariage',
  //   category: 'Mariage',
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Mariage - Shooting Photo Professionnel',
  //     description:
  //       'Séances photo professionnelles de Mariage. Capturez vos moments précieux avec un photographe spécialisé.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'bebe-lille',
  //   category: 'Bébé',
  //   hidden: true,
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Bébé à Lille - Shooting Photo Professionnel',
  //     description:
  //       'Photographe de Bébé à Lille. Séances photo professionnelles pour capturer les moments précieux.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  {
    id: 'bebe',
    category: 'Bébé',
    hidden: true,
    titre: 'Galerie photo bébé - Capture des premiers mois de votre bébé',
    titres: [
      'Votre photographe bébé à Lille',
      'Pourquoi choisir une séance photo bébé à Lille',
      'Mettez en valeur chaque instant avec une séance photo bébé à Lille',
      'Explorez votre galerie privée après chaque shooting bébé',
    ],
    cover: 'photographe-bebe-lille@digituniverse_0001',
    HtmlText: [
      'gal_bebe_pres.mdx',
      'gal_bebe_text_1.mdx',
      'gal_bebe_text_2.mdx',
      'gal_bebe_text_3.mdx',
      'gal_bebe_text_4.mdx',
    ],
    DispImage: [
      'photographe-bebe-lille@digituniverse_0002',
      'photographe-bebe-lille@digituniverse_0003',
      'photographe-bebe-lille@digituniverse_0004',
      'photographe-bebe-lille@digituniverse_0005',
    ],
    SEO: {
      title: 'Photographe pour Bébé à Lille - Shooting Photo Professionnel',
      description:
        'Photographe de Bébé à Lille. Séances photo professionnelles pour capturer les moments précieux.',
      robotsContent: 'index, follow',
    },
  },
  // {
  //   id: 'couple-lille',
  //   category: 'Couple',
  //   hidden: true,
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Couple à Lille - Shooting Photo Professionnel',
  //     description:
  //       'Photographe de Couple à Lille. Séances photo professionnelles pour capturer les moments précieux.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  {
    id: 'couple',
    category: 'Couple',
    hidden: false,
    titre: 'Galerie photo couple - Capture de votre amour et complicité',
    titres: [
      'Une séance photo couple chez Digit Universe Photographie',
      'Pourquoi une séance photo couple ?',
      'Une expérience unique à votre image',
      'Nos options de séance photo couple',
      'Comment se déroule une séance photo chez Digit Universe Photographie ?',
      'Nos tarifs et options de personnalisation',
      'Pourquoi nous choisir ?',
      'Réservez votre séance photo couple aujourd\'hui',
    ],
    cover: 'photographe-couple-lille@digituniverse_0001',
    HtmlText: [
      'gal_couple_pres.mdx',
      'gal_couple_text_1.mdx',
      'gal_couple_text_2.mdx',
      'gal_couple_text_3.mdx',
      'gal_couple_text_4.mdx',
    ],
    DispImage: [
      'photographe-couple-lille@digituniverse_0002',
      'photographe-couple-lille@digituniverse_0003',
      'photographe-couple-lille@digituniverse_0004',
      'photographe-couple-lille@digituniverse_0005',
    ],
    SEO: {
      title: 'Photographe de Couple à Lille - Shooting Photo Professionnel',
      description:
        'Photographe de Couple à Lille. Séances photo professionnelles pour capturer votre amour et complicité.',
      robotsContent: 'index, follow',
    },
  },
  // {
  //   id: 'noel-lille',
  //   category: 'Noel',
  //   hidden: true,
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0005',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Noël à Lille - Shooting Photo Professionnel',
  //     description:
  //       'Photographe de Noël à Lille. Séances photo professionnelles pour capturer les moments précieux.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'noel',
  //   category: 'Noel',
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Noël - Shooting Photo Professionnel',
  //     description:
  //       'Séances photo professionnelles de Noël. Capturez vos moments précieux avec un photographe spécialisé.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'halloween-lille',
  //   category: 'Halloween',
  //   hidden: true,
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Halloween à Lille - Shooting Photo Professionnel',
  //     description:
  //       'Photographe de Halloween à Lille. Séances photo professionnelles pour capturer les moments précieux.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'halloween',
  //   category: 'Halloween',
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Halloween - Shooting Photo Professionnel',
  //     description:
  //       'Séances photo professionnelles de Halloween. Capturez vos moments précieux avec un photographe spécialisé.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'paques-lille',
  //   category: 'Pâques',
  //   hidden: true,
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Pâques à Lille - Shooting Photo Professionnel',
  //     description:
  //       'Photographe de Pâques à Lille. Séances photo professionnelles pour capturer les moments précieux.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'paques',
  //   category: 'Pâques',
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Pâques - Shooting Photo Professionnel',
  //     description:
  //       'Séances photo professionnelles de Pâques. Capturez vos moments précieux avec un photographe spécialisé.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'professionnel-lille',
  //   category: 'Portrait professionnel',
  //   hidden: true,
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title:
  //       'Photographe pour Professionnel à Lille - Shooting Photo Professionnel',
  //     description:
  //       'Photographe de Professionnel à Lille. Séances photo professionnelles pour capturer les moments précieux.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'professionnel',
  //   category: 'Portrait professionnel',
  //   titre: 'test45123',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   SEO: {
  //     title: 'Photographe pour Professionnel - Shooting Photo Professionnel',
  //     description:
  //       'Séances photo professionnelles de Professionnel. Capturez vos moments précieux avec un photographe spécialisé.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  // {
  //   id: 'grossesse-lille',
  //   category: 'Grossesse',
  //   hidden: true,
  //   titre: 'Le shooting photo grossesse à Lille - Séance photo maternité',
  //   titres: [
  //     'Votre séance photo grossesse à Lille',
  //     'Pourquoi choisir une séance photo grossesse Lille',
  //     'Mettez en valeur votre ventre rond avec une photo femme enceinte Lille',
  //     'Explorez votre galerie privée après chaque shooting grossesse',
  //   ],
  //   cover: 'photographe-grossesse-lille@digituniverse_0001',
  //   HtmlText: [
  //     'gal_gross_lille_pres.mdx',
  //     'gal_gross_lille_text_1.mdx',
  //     'gal_gross_lille_text_2.mdx',
  //     'gal_gross_lille_text_3.mdx',
  //     'gal_gross_lille_text_4.mdx',
  //   ],
  //   DispImage: [
  //     'photographe-grossesse-lille@digituniverse_0002',
  //     'photographe-grossesse-lille@digituniverse_0003',
  //     'photographe-grossesse-lille@digituniverse_0004',
  //     'photographe-grossesse-lille@digituniverse_0001',
  //   ],
  //   images: ['photographe-grossesse-lille@digituniverse_0007', 'photographe-grossesse-lille@digituniverse_0007'],
  //   SEO: {
  //     title:
  //       'Photographe pour Grossesse à Lille - Shooting Photo Professionnell',
  //     description:
  //       'Photographe de Grossesse à Lille. Séances photo professionnelles pour immortaliser votre maternité.',
  //     robotsContent: 'index, follow',
  //   },
  // },
  {
    id: 'grossesse',
    category: 'Grossesse',
    titre: 'Séance photo maternité - Immortalisez ce moment unique',
    titres: [
      'Une séance photo grossesse sur mesure',
      'Pourquoi immortaliser votre grossesse par une séance photo ?',
      'Mettez en lumière votre grossesse avec une séance photo personnalisée',
      'Explorez votre galerie privée après chaque séance photo',
    ],
    cover: 'photographe-grossesse-lille@digituniverse_0001',
    HtmlText: [
      'gal_gross_pres.mdx',
      'gal_gross_text_1.mdx',
      'gal_gross_text_2.mdx',
      'gal_gross_text_3.mdx',
      'gal_gross_text_4.mdx',
    ],
    DispImage: [
      'photographe-grossesse-lille@digituniverse_0002',
      'photographe-grossesse-lille@digituniverse_0003',
      'photographe-grossesse-lille@digituniverse_0004',
      'photographe-grossesse-lille@digituniverse_0001',
    ],
    images: ['photographe-grossesse-lille@digituniverse_0007', 'photographe-grossesse-lille@digituniverse_0008'],
    SEO: {
      title: 'Photographe pour Grossesse - Shooting Photo Professionnel',
      description:
        'Séances photo professionnelles de Grossesse. Capturez la beauté de votre grossesse avec un photographe spécialisé.',
      robotsContent: 'index, follow',
    },
  },
];
